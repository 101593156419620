// @flow

import { declareAction, declareAtom } from '@reatom/core'
import axios from 'axios'
import * as common from './../common'
import {
  PaymentAtom,
  SetCurrentSubscriptionId,
  SetPaymentProcessor,
  SuccessfulPayment,
  UnSuccessfulPayment
} from './Payment'
import { AppConfigAtom } from './AppConfig'
// import {appleMerchantID} from "../common";
import { gtagEvent, gtagEventNew } from '../utils/gtag'
import { SubscriberAtom, SubscriberLoaded } from './Subscriber'
import { UtmAnalytics } from './UtmAnalytics'
import { PaymentType } from '../enums/PaymentTypesEnum'
import useExperimentsResolver from '../effects/experiments'
import { SizeExperiment } from '../enums/SizeExperimentEnum'
import { ErrorLog } from './Debug'
import { NREndPaymentProcess, NRError, NRStartPaymentProcess, NRSubscribed, NRSuccess } from './NewRelic'
import { BottomSheetExperiment } from '../enums/BottomSheetEnum'
import { remobyEvents, sendPostback } from '../utils/remoby'
import { PageSizeExperiment } from '../enums/PageSizeExperimentEnum'
import { useSkipStepExperiment } from '../contexts/ExperimentsContext'
import { SkipStepExperimentEnum } from '../enums/SkipStepExperimentEnum'
// import { useGeneratorExperiment } from '../contexts/ExperimentsContext'

export const CheckApplePaySupport = declareAction(
  'stripeCheckApplePaySupportAction',
  (payload, store) => {
    import('@stripe/stripe-js').then((stripeModule) => {
      let paymentType = PaymentType.OneTimePayment
      let paymentRequestUrl = `${common.api}/payment-request/stripe/apple/single-time`
      if (typeof payload !== 'undefined') {
        paymentType = payload.paymentType
        switch (paymentType) {
          case PaymentType.OneTimePayment:
            paymentRequestUrl = `${common.api}/payment-request/stripe/apple/single-time`
            break
          case PaymentType.WeeklySubscription:
            // paymentRequestUrl = `${common.api}/payment-request/stripe/apple/subscription`;
            // if (payload.bottomSheetExperiment && payload.bottomSheetExperiment === BottomSheetExperiment.New) {
            paymentRequestUrl = `${common.api}/payment-request/stripe/apple/subscription/variant1`
            // }
            break
          case PaymentType.MonthlySubscription:
            paymentRequestUrl = `${common.api}/payment-request/stripe/apple/subscription-monthly`
            break
          case PaymentType.AnnualSubscription:
            paymentRequestUrl = `${common.api}/payment-request/stripe/apple/subscription-annual`
            break
        }
      }
      store.dispatch(SetPaymentType(paymentType))
      if (window.ApplePaySession) {
        const canMakePayments = window.ApplePaySession.canMakePayments()
        if (canMakePayments) {
          store.dispatch(ApplePaySupportEnabled())
          const data = new FormData()
          data.append('paymentTypeId', payload.paymentTypeId)
          axios.post(paymentRequestUrl, data) //single time payment request
            .then(response => {
              if (response.status === 200) {
                const paymentRequestObject = response.data.request
                stripeModule.loadStripe(response.data.stripePK)
                  .then(res => {
                    const paymentRequest = res.paymentRequest(paymentRequestObject)
                    paymentRequest.canMakePayment()
                      .then(canMake => {
                        if (canMake) {
                          store.dispatch(AddConfirmationListener({
                            request: paymentRequest,
                            paymentTypeId: payload.paymentTypeId,
                            experiments: payload.experiments
                            // version: payload.generatorExperiment,
                            // pageSizeExperiment: payload.pageSizeExperiment
                          }))
                          store.dispatch(StorePaymentRequest(paymentRequest))
                        }
                      })
                      .catch(console.error)
                  })
                  .catch(console.error)
              }
            })
            .catch(err => {
              console.error(err)
              store.dispatch(ErrorLog(err))
            })
        }
        // }).catch(console.error);
      }
    })
  }
)

export const ShowStripePaymentRequest = declareAction(
  'stripeShowPaymentRequestAction',
  (payload, store) => {
    const stripeState = store.getState(StripeApplePaymentAtom)
    stripeState.paymentRequest.show()
  }
)

const AddConfirmationListener = declareAction(
  'stripeAddConfirmationListenerAction',
  (payload, store) => {
    payload.request.on('token', ev => {
      store.dispatch(NRStartPaymentProcess())
      const appState = store.getState(AppConfigAtom)
      const stripeState = store.getState(StripeApplePaymentAtom)
      const subscriberState = store.getState(SubscriberAtom)
      const utmState = store.getState(UtmAnalytics)
      const paymentState = store.getState(PaymentAtom)

      const version = payload.experiments.generatorExperiment
      const skipStepExperiment = payload.experiments.skipStepExperiment

      let confirmationUrl = `${common.api}/subscriber/${appState.fingerprint}/pay/stripe/apple`
      switch (stripeState.paymentType) {
        case PaymentType.OneTimePayment:
          confirmationUrl = `${common.api}/subscriber/${appState.fingerprint}/pay/stripe/apple`
          break
        case PaymentType.WeeklySubscription:
          confirmationUrl = `${common.api}/subscriber/${appState.fingerprint}/subscribe/stripe/apple`
          break
        case PaymentType.MonthlySubscription:
          confirmationUrl = `${common.api}/subscriber/${appState.fingerprint}/subscribe-monthly/stripe/apple`
          break
        case PaymentType.AnnualSubscription:
          confirmationUrl = `${common.api}/subscriber/${appState.fingerprint}/subscribe/stripe/apple`
          break
      }

      const paymentToken = JSON.stringify(ev.token)
      const skipStepExperimentData = {
        experimentName: 'skipStep',
        experimentValue: skipStepExperiment === SkipStepExperimentEnum.skip
          ? 'flow-3-step'
          : 'flow-4-step',
      }

      const data = {
        token: paymentToken,
        paymentTypeId: payload.paymentTypeId,
        processor: 'APPLE',
        gateway: paymentState.gateway,
        utmSource: utmState.utmSource,
        utmMedium: utmState.utmMedium,
        utmCampaign: utmState.utmCampaign,
        version: version,
        email: ev.payerEmail,
        experiments: [
          skipStepExperimentData
        ]
      }
      localStorage.setItem('payeremail', ev.payerEmail)

      axios.post(confirmationUrl, data)
        .then(
          response => {
            store.dispatch(NREndPaymentProcess())
            if (response.data.state === 'active') {
              ev.complete('success')
              const newSubscription = { ...response.data }
              delete newSubscription.subscriber
              const newSubscriptionsList = [...subscriberState.subscriber.subscriptions]
              newSubscriptionsList.push(newSubscription)
              store.dispatch(SubscriberLoaded({ ...subscriberState.subscriber, subscriptions: newSubscriptionsList }))
              store.dispatch(SetPaymentProcessor('APPLE'))
              store.dispatch(SetCurrentSubscriptionId(newSubscription.stripeId))
              store.dispatch(SuccessfulPayment({ date: response.data.expireDate, experiment: skipStepExperiment }))
              let eventData = {}
              let eventShort = ''
              switch (stripeState.paymentType) {
                case PaymentType.OneTimePayment:
                  eventData = {
                    action: 'onetimeSuccess',
                    category: 'onetimepayment',
                    label: 'one time apple pay payment'
                  }
                  eventShort = 'applepay_onetime_new'
                  break
                case PaymentType.WeeklySubscription:
                case PaymentType.AnnualSubscription:
                  eventData = {
                    action: 'subscriptionSuccess',
                    category: 'subscription',
                    label: 'apple pay subscription'
                  }
                  eventShort = 'applepay_subscription_new'
                  break
                case PaymentType.MonthlySubscription:
                  eventData = {
                    action: 'monthlySubSuccess',
                    category: 'monthlysubscription',
                    label: 'apple pay monthly subscription'
                  }
                  eventShort = 'applepay_subscription_monthly_new'
                  break
              }

              if(window.fbq && typeof window.fbq === 'function') {
                window.fbq('track', 'BFGconversion');
              }
              // window.ttq.track('Subscribe')
              // window.ttq.track('BFGconversion')
              gtagEvent(eventData)
              gtagEventNew(eventShort)

              if (SkipStepExperimentEnum.dontSkip === skipStepExperiment) {
                gtagEventNew('applepay_subscription_original')
              }
              if (SkipStepExperimentEnum.skip === skipStepExperiment) {
                gtagEventNew('applepay_subscription_experiment')
              }

              // sendPostback({ clickId: appState.remobyId, eventName: remobyEvents[4] })
              store.dispatch(NRSubscribed(NRSuccess))
            } else {
              const container = document.getElementById('apple-pay-button')
              if (container) {
                container.style.display = 'initial'
              }
              ev.complete('fail')
              store.dispatch(UnSuccessfulPayment())
              store.dispatch(NRSubscribed(NRError))
            }
          }
        )
        .catch(err => {
          console.error(err)
          store.dispatch(ErrorLog(err))
        })
    })
  }
)

const ApplePaySupportEnabled = declareAction('stripeApplePaySupportEnabledAction')
const StorePaymentRequest = declareAction('stripeStorePaymentRequestAction')
const SetPaymentType = declareAction('stripeSetPaymentTypeAction')

export const StripeApplePaymentAtom = declareAtom(
  'stripeApplePaymentAtom',
  {
    applePayEnabled: false,
    paymentRequest: null,
    paymentType: PaymentType.OneTimePayment
  },
  on => [
    on(SetPaymentType, (state, payload) => {
      return { ...state, paymentType: payload }
    }),
    on(ApplePaySupportEnabled, (state, payload) => {
      return { ...state, applePayEnabled: true }
    }),
    on(StorePaymentRequest, (state, payload) => {
      return { ...state, paymentRequest: payload }
    })
  ]
)