// @flow
import React from 'react'
import { useAtom } from '@reatom/react'
import useGoogleOptimize from '@react-hook/google-optimize'
import {
  bottomSheetExperimentId,
  firstPageExperimentId,
  payScreenExperimentId,
  sizeExperimentId,
  unsubExperimentId
} from '../common'
import { ExperimentsAtom } from '../atoms/Experiments'
import { SizeExperiment } from '../enums/SizeExperimentEnum'
import { UnsubExperiment } from '../enums/UnsubEnum'
import { PayScreenExperiment } from '../enums/PayScreenEnum'
import { BottomSheetExperiment } from '../enums/BottomSheetEnum'
import { FirstPageExperiment } from '../enums/FirstPageEnum'
import { UtmAnalytics } from '../atoms/UtmAnalytics'
import { gtagEvent, gtagEventNew } from '../utils/gtag'
import Cookies from 'js-cookie'
import { ArrowEnum } from '../enums/ArrowEnum'
import { GeneratorExperiment } from '../enums/GeneratorExperimentEnum'
import { PageSizeExperiment } from '../enums/PageSizeExperimentEnum'
import {SkipStepExperimentEnum} from "../enums/SkipStepExperimentEnum";

const initSizeExperiment = () => {
  let sizeExperiment = useGoogleOptimize(
    sizeExperimentId,
    [
      SizeExperiment.RegularSize, //50
      SizeExperiment.ShortSize, //50
    ]
  )
  if (sizeExperiment === null) {
    sizeExperiment = SizeExperiment.RegularSize
  }
  return sizeExperiment
}

const initUnsubExperiment = () => {
  let unsubExperiment = useGoogleOptimize(
    unsubExperimentId,
    [
      UnsubExperiment.Unsubscribe, //20
      UnsubExperiment.Profile, //20
      UnsubExperiment.Membership, //20
      UnsubExperiment.OptOut, //20
      UnsubExperiment.Unregister, //20
    ]
  )
  if (unsubExperiment === null) {
    unsubExperiment = UnsubExperiment.Unsubscribe
  }
  return unsubExperiment
}

const initPayScreenExperiment = () => {
  let payScreenExperiment = useGoogleOptimize(
    payScreenExperimentId,
    [
      PayScreenExperiment.Old, //34
      PayScreenExperiment.New, //33
      PayScreenExperiment.New2, //33
    ]
  )
  if (payScreenExperiment === null) {
    payScreenExperiment = PayScreenExperiment.Old
  }
  return payScreenExperiment
}

const initBottomSheetExperiment = () => {
  let bottomSheetExperiment = useGoogleOptimize(
    bottomSheetExperimentId,
    [
      BottomSheetExperiment.Old, //50
      BottomSheetExperiment.New, //50
    ]
  )
  if (bottomSheetExperiment === null) {
    bottomSheetExperiment = BottomSheetExperiment.Old
  }
  return bottomSheetExperiment
}

const initFirstPageExperiment = () => {
  // const utm = useAtom(UtmAnalytics)
  // const firstPageExperiment = utm.utmSource === 'test-page' ? FirstPageExperiment.New : FirstPageExperiment.Old;
  const variants = [FirstPageExperiment.Old, FirstPageExperiment.New]
  const firstPageExperiment = variants[bfgExperiments.FirstPageAB]
  const eventData = {
    action: `testExperimentFirstPageAB_${bfgExperiments.FirstPageAB}`,
    category: `testexperimentfirstpageab_${bfgExperiments.FirstPageAB}`,
    label: `test.bfg experiment variant ${bfgExperiments.FirstPageAB}`
  }
  gtagEvent(eventData)
  return firstPageExperiment
}

const initArrowExperiment = () => {
  const variants = [ArrowEnum.Old, ArrowEnum.New]
  const arrowExperiment = variants[bfgExperiments.ArrowAB]
  if (typeof Cookies.get('arrowExperimentFired') === 'undefined') {
    const eventData = {
      action: `experimentArrowAB_${bfgExperiments.ArrowAB}`,
      category: `experimentarrowab_${bfgExperiments.ArrowAB}`,
      label: `bfg arrow variant ${bfgExperiments.ArrowAB}`
    }
    gtagEvent(eventData)
    Cookies.set('arrowExperimentFired', 'true', { expires: 30, domain: '.babyfacegenerator.com' })
  }
  return arrowExperiment
}

const initGeneratorExperiment = () => {
  // GeneratorExperiment.AI alg30
  // GeneratorExperiment.Alg100

  const variants = [GeneratorExperiment.AI, GeneratorExperiment.Alg100]
  let generatorExperimentAB = variants[bfgExperiments.GeneratorAB]
  const utm = useAtom(UtmAnalytics)
  if (utm.utmSource === 'alg100') {
    generatorExperimentAB = GeneratorExperiment.Alg100
  }
  if (typeof Cookies.get('generatorExperimentFired') === 'undefined') {
    // gtagEventNew(`experimentGeneratorAB_${bfgExperiments.GeneratorAB}`);
    Cookies.set('generatorExperimentFired', 'true', { expires: 30, domain: '.babyfacegenerator.com' })
  }
  return generatorExperimentAB
}

const initPageSizeExperiment= () => {
  const variants = [PageSizeExperiment.long,PageSizeExperiment.short]
  let pageSizeExperimentAB = variants[bfgExperiments.PageSizeAB]
  // const utm = useAtom(UtmAnalytics)
  // if (utm.utmSource === 'alg100') {
  //   pageSizeExperimentAB = GeneratorExperiment.Alg100
  // }
  if (typeof Cookies.get('pageSizeExperimentFired') === 'undefined') {
    if(pageSizeExperimentAB === PageSizeExperiment.long) {
      gtagEventNew(`original_loaded`);
    }
    if(pageSizeExperimentAB === PageSizeExperiment.short){
      gtagEventNew(`experiment_loaded`);
    }
    Cookies.set('pageSizeExperimentFired', 'true', { expires: 30, domain: '.babyfacegenerator.com' })
  }
  return pageSizeExperimentAB
}

const initSkipStepExperiment = () => {
  const variants = [ SkipStepExperimentEnum.skip,  SkipStepExperimentEnum.dontSkip]
  let skipStepExperimentAB = variants[bfgExperiments.SkipStepAB]
  const utm = useAtom(UtmAnalytics)
  if (utm.utmSource === 'skipstep') {
    skipStepExperimentAB = SkipStepExperimentEnum.skip
  }
  if (typeof Cookies.get('skipStepExperimentFired') === 'undefined') {
    if(skipStepExperimentAB === SkipStepExperimentEnum.dontSkip) {
      gtagEventNew(`original_loaded`);
    }
    if(skipStepExperimentAB === SkipStepExperimentEnum.skip){
      gtagEventNew(`experiment_loaded`);
    }
    // gtagEventNew(`experimentGeneratorAB_${bfgExperiments.GeneratorAB}`);
    Cookies.set('skipStepExperimentFired', 'true', { expires: 30, domain: '.babyfacegenerator.com' })
    // Cookies.set('skipStepExperimentFired', 'true', { expires: 30, domain: '.bfg.localhost' })
  }
  return skipStepExperimentAB
}

const useExperimentsResolver = () => {
  const experimentsAtomState = useAtom(ExperimentsAtom)
  const data = {
    sizeExperiment: SizeExperiment.RegularSize,
    unsubExperiment: UnsubExperiment.Membership,
    payScreenExperiment: PayScreenExperiment.New2,
    bottomSheetExperiment: BottomSheetExperiment.New,
    firstPageExperiment: FirstPageExperiment.Old,
    arrowExperiment: ArrowEnum.Old,
    aiExperiment: GeneratorExperiment.Alg100,
    pageSizeExperiment: PageSizeExperiment.short,

    skipThreeStepExperiment:  SkipStepExperimentEnum.skip
  }

  data.skipThreeStepExperiment = experimentsAtomState.skipStepExperiment === null
    ? initSkipStepExperiment()
    : experimentsAtomState.skipStepExperiment;

  // data.sizeExperiment = experimentsAtomState.sizeExperiment === null
  //   ? initSizeExperiment()
  //   : experimentsAtomState.sizeExperiment;

  // data.arrowExperiment = experimentsAtomState.arrowExperiment === null
  //   ? initArrowExperiment()
  //   : experimentsAtomState.arrowExperiment;

  // data.firstPageExperiment = experimentsAtomState.firstPageExperiment === null
  //   ? initFirstPageExperiment()
  //   : experimentsAtomState.firstPageExperiment;

  // data.payScreenExperiment = experimentsAtomState.payScreenExperiment === null
  //   ? initPayScreenExperiment()
  //   : experimentsAtomState.payScreenExperiment;

  // data.bottomSheetExperiment = experimentsAtomState.bottomSheetExperiment === null
  //   ? initBottomSheetExperiment()
  //   : experimentsAtomState.bottomSheetExperiment;

  // data.unsubExperiment = experimentsAtomState.unsubExperiment === null
  //   ? initUnsubExperiment()
  //   : experimentsAtomState.unsubExperiment;
  // data.aiExperiment = initGeneratorExperiment()
  //
  // data.pageSizeExperiment= experimentsAtomState.pageSizeExperiment === null
  //   ? initPageSizeExperiment()
  //   : experimentsAtomState.pageSizeExperiment;

  return data
}

export default useExperimentsResolver
